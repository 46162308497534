import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const PioneersProgram = () => {
  return (
    <>
      <Navbar />
      <section className="mt-24">
        <section className="relative bg-blight w-full h-[60vh] pt-[5rem] flex justify-center bg-gradient-to-r from-[#07052D] to-[#171093] items-center overflow-hidden overlow-hidden">
          <span className="absolute inse=t-0 z-0 p-5  justif-center top-[rem]"></span>
          <section>
            <figcaption className="desc z-10 relative">
              <h1 className="text-3xl md:4xl lg:text-6xl  md:text-5xl lg:text-6xl font-bold text-white mb-6 text-center">
                Pioneers Program
              </h1>
              <h2 className="text-md md:text-xl lg:text-2xl font-semibold text-gray-200 mb-4 text-center">
                Be a Trailblazer in AI-Powered Education
              </h2>
              <p className="text-gray-100 mb-8 px-2 max-w-2xl text-sm md:text-lg xl:text-lg text-center">
                Are you an educator committed to transforming teaching with
                innovative technology? The AI Teacha Pioneers Program is your
                opportunity to lead the charge in revolutionizing education
                across Nigeria and Africa.
              </p>
            </figcaption>
          </section>
        </section>
      </section>

      <div className="bg-gray-50 py-16 px-6 sm:px-12 lg:px-16">
        <div className="max-w-4xl mx-auto">
          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Why Join the AI Teacha Pioneers Program?
              </h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>
                  <span className="font-bold">Empower Your Teaching:</span> Gain
                  access to cutting-edge AI tools designed to save time,
                  personalize learning, and enhance classroom engagement.
                </li>
                <li>
                  <span className="font-bold">Be a Leader:</span> Shape the
                  future of AI Teacha by providing valuable insights and
                  feedback to improve the platform for educators.
                </li>
                <li>
                  <span className="font-bold">Exclusive Benefits:</span> Receive
                  perks like early access to new tools, mentorship
                  opportunities, and global networking.
                </li>
                <li>
                  <span className="font-bold">Combat Teacher Burnout:</span>{" "}
                  Leverage AI to simplify administrative tasks, giving you more
                  time to focus on student growth and innovation.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Eligibility Requirements
              </h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>
                  <span className="font-bold">
                    AI Teacha Certification Level 1:
                  </span>{" "}
                  Complete the AI Teacha Level 1 Certification and upload proof
                  of certification (a screenshot of your certificate). Haven’t
                  completed the certification?{" "}
                  <a
                    href="https://uni.icedt.org/course/AI-TEACHER-CERTIFICATION-LEVEL-1"
                    className="text-primary hover:underline"
                  >
                    Take the course here
                  </a>
                  .
                </li>
                <li>
                  <span className="font-bold">
                    Commitment to Education Innovation:
                  </span>{" "}
                  Demonstrate your passion for advancing education through
                  AI-driven solutions.
                </li>
                <li>
                  <span className="font-bold">Active Engagement:</span> Share
                  experiences, favorite tools, and insights to help shape AI
                  Teacha into an indispensable platform for educators.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Application Process
              </h3>
              <ol className="list-decimal pl-6 space-y-2 text-gray-700">
                <li>Complete your AI Teacha Level 1 Certification.</li>
                <li>
                  Fill out the application form with the following required
                  details:
                  <ul className="list-disc pl-6 space-y-2">
                    <li>
                      <span className="font-bold">Personal Information:</span>{" "}
                      Name, email, phone number, and role in your school or
                      organization.
                    </li>
                    <li>
                      <span className="font-bold">School Details:</span> Name of
                      your school, Local Government Area (LGA), and state.
                    </li>
                    <li>
                      <span className="font-bold">Motivation Statement:</span>{" "}
                      Share why you want to join the program and how you plan to
                      contribute.
                    </li>
                    <li>
                      <span className="font-bold">
                        Experience and Social Media:
                      </span>{" "}
                      Highlight your experiences and provide at least one active
                      social media handle.
                    </li>
                    <li>
                      <span className="font-bold">
                        Favorite AI Teacha Tools:
                      </span>{" "}
                      Tell us your favorite tools on AI Teacha and why you love
                      them.
                    </li>
                  </ul>
                </li>
                <li>
                  Upload proof of your certification (maximum file size: 10 MB).
                </li>
              </ol>
            </div>

            <div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Important Information for Applicants
              </h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>
                  <span className="font-bold">High Demand:</span> Due to the
                  popularity of the program, individual inquiries about
                  application status may not be addressed.
                </li>
                <li>
                  <span className="font-bold">Processing Time:</span>{" "}
                  Applications are reviewed weekly, with an average processing
                  time of two weeks.
                </li>
                <li>
                  <span className="font-bold">Approval Timeline:</span> Approved
                  applicants will receive further instructions via email. Ensure
                  your contact details are accurate.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Your Role as an AI Teacha Pioneer
              </h3>
              <p className="text-gray-700">
                As a Pioneer, you’ll lead by example, showcasing how AI can
                revolutionize education. You will:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>
                  Mentor other educators on integrating AI into their
                  classrooms.
                </li>
                <li>
                  Provide feedback to enhance AI Teacha tools and features.
                </li>
                <li>
                  Collaborate with a global community of educators passionate
                  about AI in education.
                </li>
              </ul>
            </div>

            <div className="text-center mt-8">
              <a
                href="https://docs.google.com/forms/d/1brhKX9Zbe9AtsFrfu0Z6iAoQ7gBbnmaH5PA29LC878c/edit#response=ACYDBNgfiJqvMIjmWKW5diq2zmQDa13coA_zQPeZmw51wKqTu3w2eKDmkg0EgjmhfxOCjJk"
                className="inline-block bg-primary text-white py-3 px-6 rounded-full text-lg font-semibold hover:bg-blue-700 transition"
              >
                Apply Now to Join the AI Teacha Pioneers Program
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PioneersProgram;
