// import React, { useState, useEffect } from "react";
// import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
// import { Switch } from "../../components/ui/Switch";
// import { Button } from "../../components/ui/Button";
// import { FLUTTERWAVE_PUBLIC } from "../../lib/utils";
// import Logo from "../../assets/img/logo.png";
// import { changeUserPlan } from "../../api/subscription";
// import { useNavigate } from "react-router-dom";
// import {
//   ToastProvider,
//   Toast,
//   ToastTitle,
//   ToastDescription,
//   ToastViewport,
//   ToastClose,
// } from "../../components/ui/Toast";
// import PricingFaq from "../Landing/components/PricingFaq";
// import PaymentMethodDialog from "./UpgradeDialog";
// interface UserDetails {
//   id: string;
//   email: string;
//   role: string;
//   package: string;
//   firstname: string;
// }

// const Upgrade: React.FC = () => {
//   const navigate = useNavigate();
//   const [billingCycle, setBillingCycle] = useState<"month" | "year">(
//     "month"
//   );
//   const [currency, setCurrency] = useState<"NGN" | "USD" | "GBP">("NGN");
//   const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
//   const [loadingPlan, setLoadingPlan] = useState<
//     "free" | "pro" | "premium" | "enterprise" | null
//   >(null);
//   const [showToast, setShowToast] = useState(false);
//   const [toastMessage, setToastMessage] = useState("");
//   const [toastVariant, setToastVariant] = useState<"default" | "destructive">(
//     "default"
//   );

//   const prices = {
//     free: {
//       NGN: { month: "₦0", year: "₦0" },
//       USD: { month: "$0", year: "$0" },
//       GBP: { month: "£0", year: "£0" },
//     },
//     pro: {
//       NGN: { month: 5000, year: 55000 },
//       USD: { month: 5, year: 55 },
//       GBP: { month: 4, year: 50 },
//     },
//     premium: {
//       NGN: { month: 20000, year: 200000 },
//       USD: { month: 20, year: 200 },
//       GBP: { month: 18, year: 190 },
//     },
//     enterprise: {
//       NGN: { month: 100000, year: 1200000 },
//       USD: { month: 100, year: 1200 },
//       GBP: { month: 96, year: 1180 },
//     },
//   };

//   useEffect(() => {
//     const storedUser = localStorage.getItem("ai-teacha-user");
//     if (storedUser) {
//       const userData: UserDetails = JSON.parse(storedUser);
//       setUserDetails(userData);
//     }
//   }, []);

//   const getFlutterwaveConfig = (plan: "pro" | "premium" | "enterprise") => ({
//     public_key: FLUTTERWAVE_PUBLIC,
//     tx_ref: `TX_${Date.now()}`,
//     amount: prices[plan][currency][billingCycle],
//     currency: currency,
//     payment_options: "card, banktransfer, ussd",
//     customer: {
//       email: userDetails?.email || "default@email.com",
//       phone_number: "08012345678",
//       name: userDetails?.firstname || "Default User",
//     },
//     customizations: {
//       title: `${plan.charAt(0).toUpperCase() + plan.slice(1)} Plan`,
//       description: `Upgrade to ${
//         plan.charAt(0).toUpperCase() + plan.slice(1)
//       } Plan`,
//       logo: Logo,
//     },
//   });

//   const handlePayment = async (
//     plan: "free" | "pro" | "premium" | "enterprise"
//   ) => {
//     setLoadingPlan(plan);

//     const packageMap = {
//       free: 1,
//       pro: 2,
//       premium: 3,
//       enterprise: 4,
//     };
//     const packageId = packageMap[plan];
//     const userId = parseInt(userDetails?.id || "0", 10);
//     const duration = billingCycle === "year" ? "year" : "month";
//     const updateLocalStorage = (updatedPlan: string) => {
//       const storedUser = localStorage.getItem("ai-teacha-user");
//       if (storedUser) {
//         const userData = JSON.parse(storedUser);
//         const updatedUserData = { ...userData, package: updatedPlan };
//         localStorage.setItem("ai-teacha-user", JSON.stringify(updatedUserData));
//         setUserDetails(updatedUserData);
//       }
//     };
//     if (plan === "free") {
//       try {
//         const unit = 1;
//         await changeUserPlan(packageId, userId, 1, duration, currency);
//         setToastMessage("updated to free plan!");
//         setToastVariant("default");
//       } catch (err) {
//         console.error(err);
//         setToastMessage("Failed to switch to the Free plan.");
//         setToastVariant("destructive");
//       } finally {
//         setShowToast(true);
//         setLoadingPlan(null);
//       }
//       return;
//     }

//     const config = getFlutterwaveConfig(plan);
//     const handleFlutterPayment = useFlutterwave(config);

//     handleFlutterPayment({
//       callback: async (response) => {
//         console.log(response.status);
//         if (response.status === "completed") {
//           await changeUserPlan(packageId, userId, 1, duration, currency);
//           const capitalize = (str: string) => {
//             return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
//           };

//           updateLocalStorage(`AI Teacha ${capitalize(plan)}`);
//           setToastMessage("Payment Successful");
//           setToastVariant("default");
//         } else {
//           setToastMessage("Payment Failed or Cancelled");
//           setToastVariant("destructive");
//         }
//         closePaymentModal();
//         setLoadingPlan(null);
//       },
//       onClose: () => {
//         setLoadingPlan(null);
//         console.log("Payment modal closed");
//       },
//     });
//   };

//   const toggleBillingCycle = () => {
//     setBillingCycle((prev) => (prev === "month" ? "year" : "month"));
//   };

//   const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     setCurrency(e.target.value as "NGN" | "USD");
//   };
//   const getCurrencySign = (currency: "NGN" | "USD" | "GBP") => {
//     if (currency === "NGN") {
//       return "₦";
//     } else if (currency === "USD") {
//       return "$";
//     } else if (currency === "GBP") {
//       return "£";
//     }
//     return "";
//   };

//   return (
//     <ToastProvider>
//       <div className="mt-12">
//         <div className="flex justify-between">
//           <h2 className="text-xl font-medium text-gray-900 mb-4">
//             Welcome Back! 👋
//           </h2>
//           <div className="w-60">
//             <label className="text-gray-700 font-medium mb-2 block">
//               Select Currency
//             </label>
//             <select
//               className="border rounded-md w-full py-2 px-3"
//               value={currency}
//               onChange={handleCurrencyChange}
//             >
//               <option value="NGN">NGN (Naira)</option>
//               <option value="USD">USD (Dollar)</option>
//               <option value="GBP">GBP (Pounds)</option>
//             </select>
//           </div>
//         </div>
//         <h2 className=" text-xl font-bold ">
//           Simple & Transparent Pricing for Educators & Schools
//         </h2>
//         <h2>
//           We offer a Free plan for educators with limited access, a Pro plan
//           with more Pro tools and classroom features, a Premium plan with full
//           AI Teacha suite for schools with maximum number of 15 educators and an
//           Enterprise plan for larger schools with more than 15 educators.
//         </h2>
//         <div className="mb-4 mt-4 w-60 mx-auto flex items-center justify-between">
//           <span className="text-xl font-medium text-gray-800">Month</span>
//           <Switch
//             checked={billingCycle === "year"}
//             onCheckedChange={toggleBillingCycle}
//             thumbColor="primary"
//           />
//           <span className="text-xl font-medium text-gray-800">Year</span>
//         </div>
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//           <div className="border rounded-lg p-6 bg-gray-50 shadow-sm flex flex-col">
//             <h3 className="text-lg font-semibold mb-4">AI Teacha Free</h3>
//             <p className="text-2xl font-bold mb-2">
//               {prices.free[currency][billingCycle]}
//             </p>

//             {billingCycle === "year" && (
//               <span className="font-medium text-sm text-gray-700"> </span>
//             )}
//             <p className="mb-4 mt-2 text-sm text-gray-600">
//               Get started for Free, learn how AI Teacha saves you time and
//               generates tailored resources.
//             </p>
//             <ul className="list-disc pl-5 space-y-2 mb-6 flex-grow">
//               <strong>Save time, get resources...</strong>
//               <li>Unlimited use of our essential free tools</li>
//               <li>Generate tailored, high-quality resources</li>
//               <li>
//                 15 Time-Saving Tools to simplify lesson planning, assessments,
//                 and more
//               </li>
//               <li>Easily download and save your generated resources</li>
//               <li>
//                 Interact with Zyra, our AI Chat Assistant, built exclusively for
//                 educators and students
//               </li>
//               <li>AI Image generation for educators and students</li>
//             </ul>
//             <button
//               className={`w-full py-2 rounded-md mt-auto ${
//                 userDetails?.package === "Free"
//                   ? "bg-gray-300 text-gray-700 cursor-not-allowed"
//                   : "bg-primary text-white hover:bg-[#4a2fa3] transition"
//               }`}
//               disabled={userDetails?.package === "AI Teacha Free"}
//             >
//               {userDetails?.package === "AI Teacha Free"
//                 ? "Current Plan"
//                 : "Downgrade to Free"}
//             </button>
//           </div>
//           <div className="border rounded-lg p-6 bg-gray-50 shadow-md flex flex-col">
//             <h3 className="text-lg font-semibold mb-4">AI Teacha Pro</h3>
//             <p className="text-2xl font-bold mb-2">
//               {getCurrencySign(currency)} {prices.pro[currency][billingCycle]}
//               {currency === "USD" && billingCycle === "year" && (
//                 <span className="font-medium text-sm text-gray-700">
//                   {" "}
//                   {getCurrencySign(currency)}
//                   {(prices.pro[currency][billingCycle] / 12).toFixed(2)} month
//                 </span>
//               )}
//               {currency === "GBP" && billingCycle === "year" && (
//                 <span className="font-medium text-sm text-gray-700">
//                   {" "}
//                   {getCurrencySign(currency)}
//                   {(prices.pro[currency][billingCycle] / 12).toFixed(2)} month
//                 </span>
//               )}
//               {billingCycle === "year" && currency === "NGN" && (
//                 <span className="font-medium text-sm text-gray-700">
//                   {" "}
//                   ₦4,585 month
//                 </span>
//               )}
//             </p>
//             <p className="mb-4 mt-2 text-sm text-gray-600">
//               Upgrade to AI Teacha Pro for unlimited access to all resources and
//               pro tools.
//             </p>
//             <ul className="list-disc pl-5 space-y-2 mb-6 flex-grow">
//               <strong>Everything in Free, Plus...</strong>
//               <li>Unlock all 37 advanced, time-saving AI tools</li>
//               <li>Unlimited content generation as you need</li>
//               <li>
//                 Generate unlimited AI-powered slides, exportable directly to
//                 Microsoft PowerPoint
//               </li>
//               <li>Unlimited assignments for student evaluation needs</li>
//               <li>
//                 Unlimited student performance reports to track and enhance
//                 learning outcomes
//               </li>
//               <li>
//                 Unlimited AI-generated images perfect for engaging lessons
//               </li>
//               <li>Exclusive early access to new features and tools</li>
//             </ul>
//             <Button
//               onClick={() => handlePayment("pro")}
//               disabled={
//                 loadingPlan === "pro" ||
//                 userDetails?.package === "AI Teacha Pro"
//               }
//               className={`bg-primary text-white w-full py-2 rounded-md transition mt-auto text-center ${
//                 userDetails?.package === "AI Teacha Pro"
//                   ? "bg-gray-300 text-gray-700 cursor-not-allowed"
//                   : "hover:bg-[#4a2fa3]"
//               }`}
//             >
//               {userDetails?.package === "AI Teacha Pro"
//                 ? "Current Plan"
//                 : loadingPlan === "pro"
//                 ? "Processing..."
//                 : "Upgrade to Pro"}
//             </Button>
//           </div>
//           <div className="border rounded-lg p-6 bg-gray-50 shadow-md flex flex-col">
//             <h3 className="text-lg font-semibold mb-4">AI Teacha Premium</h3>
//             <p className="text-2xl font-bold mb-2">
//               {getCurrencySign(currency)}{" "}
//               {prices.premium[currency][billingCycle]}
//               {currency === "USD" && billingCycle === "year" && (
//                 <span className="font-medium text-sm text-gray-700">
//                   {" "}
//                   {getCurrencySign(currency)}
//                   {(prices.premium[currency][billingCycle] / 12).toFixed(
//                     2
//                   )}{" "}
//                   month
//                 </span>
//               )}
//               {currency === "GBP" && billingCycle === "year" && (
//                 <span className="font-medium text-sm text-gray-700">
//                   {" "}
//                   {getCurrencySign(currency)}
//                   {(prices.premium[currency][billingCycle] / 12).toFixed(
//                     2
//                   )}{" "}
//                   month
//                 </span>
//               )}
//               {billingCycle === "year" && currency === "NGN" && (
//                 <span className="font-medium text-sm text-gray-700">
//                   {" "}
//                   ₦20,385 month
//                 </span>
//               )}
//             </p>
//             <p className="mb-4 mt-2 text-sm text-gray-600">
//               Full AI Teacha suite for schools with classroom, assignment, and
//               report features.
//             </p>
//             <ul className="list-disc pl-5 space-y-2 mb-6 flex-grow">
//               <strong>Everything in Pro, Plus...</strong>
//               <li>
//                 Institution-wide monitoring of teachers and students activity
//               </li>
//               <li>
//                 Moderation features to prioritize student safety and compliance
//               </li>
//               <li>Data Privacy Agreements (DPA)</li>
//               <li>Personalized AI training and tool customizations</li>
//               <li>
//                 Special pricing and discounts on bulk licenses for schools
//               </li>
//               <li>Unlimited chat and resource histories</li>
//               <li>Unlimited number of educators</li>
//               <li>Dedicated support for your school or institution</li>
//             </ul>
//             <Button
//               onClick={() => handlePayment("premium")}
//               disabled={
//                 loadingPlan === "premium" ||
//                 userDetails?.package === "AI Teacha Premium"
//               }
//               className={`bg-primary text-white w-full py-2 rounded-md transition mt-auto text-center ${
//                 userDetails?.package === "premium"
//                   ? "bg-gray-300 text-gray-700 cursor-not-allowed"
//                   : "hover:bg-[#4a2fa3]"
//               }`}
//             >
//               {userDetails?.package === "AI Teacha Premium"
//                 ? "Current Plan"
//                 : loadingPlan === "premium"
//                 ? "Processing..."
//                 : "Upgrade to Premium"}
//             </Button>
//           </div>
//         </div>
//         <div className="border rounded-lg mt-4 text-center p-6 bg-gray-50 shadow-md flex flex-col">
//           <h3 className="text-lg font-semibold ">AI Teacha Enterprise</h3>
//           <p className="mb-4 mt-2 text-sm text-gray-600">
//             Custom discounted pricing for schools, districts, institutions, and
//             tutorial centers.
//           </p>
//           <ul className="list-none pl-5 space-y-2 mb-6 flex-grow">
//             <strong>Everything in Premium, Plus...</strong>
//             <li>Designed for large schools and institutions</li>
//             <li>
//               The AI Teacha Enterprise Plan is tailored for organizations with
//               15 or more educators seeking comprehensive AI solutions
//             </li>
//             <li>
//               Contact us today or use our Quote Calculator to receive customized
//               pricing and exclusive discounts for your institution.
//             </li>
//           </ul>
//           <Button
//             onClick={() => navigate("/dashboard/upgrade/support")}
//             disabled={
//               loadingPlan === "enterprise" ||
//               userDetails?.package === "Ai Teacha Enterprise"
//             }
//             className={`bg-primary text-white w-full py-2 rounded-md transition mt-auto text-center ${
//               userDetails?.package === "Ai Teacha Enterprise"
//                 ? "bg-gray-300 text-gray-700 cursor-not-allowed"
//                 : "hover:bg-[#4a2fa3]"
//             }`}
//           >
//             {" "}
//             {userDetails?.package === "Ai Teacha Enterprise"
//               ? "Current Plan"
//               : loadingPlan === "enterprise"
//               ? "Processing..."
//               : "Contact Support"}{" "}
//           </Button>{" "}
//         </div>
//         <div className="mt-12">
//           <PricingFaq />
//         </div>
//       </div>
//       {showToast && (
//         <Toast variant={toastVariant} onOpenChange={setShowToast}>
//           <ToastTitle>
//             {toastVariant === "destructive" ? "Error" : "Success"}
//           </ToastTitle>
//           <ToastDescription>{toastMessage}</ToastDescription>
//           <ToastClose />
//         </Toast>
//       )}
//       <ToastViewport />
//     </ToastProvider>
//   );
// };

// export default Upgrade;
import React, { useState, useEffect } from "react";
import { Button } from "../../components/ui/Button";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { changeUserPlan } from "../../api/subscription";
import { FLUTTERWAVE_PUBLIC } from "../../lib/utils";
import Logo from "../../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  ToastProvider,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastViewport,
  ToastClose,
} from "../../components/ui/Toast";
import PricingFaq from "../Landing/components/PricingFaq";
import PaymentMethodDialog from "./UpgradeDialog";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { Switch } from "../../components/ui/Switch";
interface UserDetails {
  id: string;
  email: string;
  role: number;
  package: string;
  firstname: string;
}

const Upgrade: React.FC = () => {
  const navigate = useNavigate();
  const [billingCycle, setBillingCycle] = useState<"month" | "year">("month");
  const [currency, setCurrency] = useState<"NGN" | "USD" | "GBP">("NGN");
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<
    "pro" | "premium" | "enterprise" | "admin" | null
  >(null);
  const [loadingPlan, setLoadingPlan] = useState<null | string>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem("ai-teacha-user");
    if (storedUser) {
      const userData: UserDetails = JSON.parse(storedUser);
      setUserDetails(userData);
    }
  }, []);

  const prices = {
    free: {
      NGN: { month: "₦0", year: "₦0" },
      USD: { month: "$0", year: "$0" },
      GBP: { month: "£0", year: "£0" },
    },
    pro: {
      NGN: { month: 5000, year: 55000 },
      USD: { month: 5, year: 55 },
      GBP: { month: 4, year: 50 },
    },
    premium: {
      NGN: { month: 20000, year: 200000 },
      USD: { month: 20, year: 200 },
      GBP: { month: 18, year: 190 },
    },
    enterprise: {
      NGN: { month: 100000, year: 1200000 },
      USD: { month: 100, year: 1200 },
      GBP: { month: 96, year: 1180 },
    },
    admin: {
      NGN: { month: 50, year: 50 },
      USD: { month: 1, year: 1 },
      GBP: { month: 1, year: 1 },
    },
  };

  const getFlutterwaveConfig = (
    plan: "pro" | "premium" | "enterprise" | "admin"
  ) => ({
    public_key: FLUTTERWAVE_PUBLIC,
    tx_ref: `TX_${Date.now()}`,
    amount: prices[plan][currency][billingCycle],
    currency: currency,
    payment_options: "card, banktransfer, ussd",
    customer: {
      email: userDetails?.email || "default@email.com",
      phone_number: "08012345678",
      name: userDetails?.firstname || "Default User",
    },
    customizations: {
      title: `${plan.charAt(0).toUpperCase() + plan.slice(1)} Plan`,
      description: `Upgrade to ${
        plan.charAt(0).toUpperCase() + plan.slice(1)
      } Plan`,
      logo: Logo,
    },
  });

  const packageMap = {
    free: 1,
    pro: 2,
    premium: 3,
    enterprise: 4,
    admin: 2,
  };

  const handlePayment = async (
    method: "stripe" | "flutterwave",
    plan: "pro" | "premium" | "enterprise" | "admin"
  ) => {
    setLoadingPlan(plan);

    const amount = prices[plan][currency][billingCycle];
    console.log("Selected Payment Method:", method);

    if (method === "flutterwave") {
      const config = getFlutterwaveConfig(plan);

      // Open a blank new window
      const flutterwaveWindow = window.open("", "_blank");

      if (!flutterwaveWindow) {
        console.error("Failed to open new window for Flutterwave");
        setLoadingPlan(null);
        return;
      }

      // Write an HTML template into the new window
      flutterwaveWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
          <head>
            <title>Flutterwave Payment</title>
            <script src="https://checkout.flutterwave.com/v3.js"></script>
          </head>
          <body>
            <script>
              const config = ${JSON.stringify(config)};
  
              function makePayment() {
                FlutterwaveCheckout({
                  ...config,
                  callback: async (response) => {
                       console.log(response);
                    if (response.status === "completed" || response.status === "success" || response.status === "successful") {
                 
                      window.opener.postMessage({ status: "completed" }, "*");
                         window.opener.postMessage({ status: "success" }, "*");
                            window.opener.postMessage({ status: "successful" }, "*");
                    } else {
                      console.error("Payment failed");
                      window.opener.postMessage({ status: "failed" }, "*");
                    }
                     window.close();
                  },
                  onclose: () => {
                    console.log("Payment modal closed");
                    window.opener.postMessage({ status: "closed" }, "*");
                     window.close();
                  }
                });
              }
  
              makePayment();
            </script>
          </body>
        </html>
      `);

      window.addEventListener("message", (event) => {
        console.log(event);
        if (
          event.data?.status === "completed" ||
          event.data?.status === "success" ||
          event.data?.status === "successful"
        ) {
          const packageId = packageMap[plan];
          changeUserPlan(
            packageId,
            parseInt(userDetails?.id || "0", 10),
            1,
            billingCycle,
            currency
          )
            .then(() => {
              console.log("User plan updated successfully");
              window.location.reload();
            })

            .catch((err) => console.error("Error updating user plan:", err));
        } else if (event.data?.status === "failed") {
          console.error("Payment failed in Flutterwave");
        } else {
          console.log("Payment process canceled or closed");
        }
        setLoadingPlan(null);
      });
    } else if (method === "stripe") {
      const token = Cookies.get("at-refreshToken");
      if (!token) {
        console.error("No refresh token found");
        setLoadingPlan(null);
        return;
      }

      try {
        const response = await axios.post(
          "https://vd.aiteacha.com/api/payment/stripe/initiate",
          {
            user_id: parseInt(userDetails?.id || "0", 10),
            package_id: plan === "pro" ? 2 : plan === "premium" ? 3 : 4,
            amount: amount,
            currency: currency,
            interval: billingCycle,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.status === "success") {
          const paymentLink = response.data.data.paymentLink;
          window.open(paymentLink, "_blank");
        } else {
          console.error(
            "Error creating Stripe session:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("Error initiating Stripe payment:", error);
      } finally {
        setLoadingPlan(null);
      }
    }
  };

  const getCurrencySign = (currency: "NGN" | "USD" | "GBP") => {
    if (currency === "NGN") {
      return "₦";
    } else if (currency === "USD") {
      return "$";
    } else if (currency === "GBP") {
      return "£";
    }
    return "";
  };
  const toggleBillingCycle = () => {
    setBillingCycle((prev) => (prev === "month" ? "year" : "month"));
  };

  const isPaymentPage = window.location.pathname === "/payment";

  return (
    <div className="mt-12">
      <div className="flex justify-between">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Welcome! 👋</h2>
        <div className="w-60">
          <label className="text-gray-700 font-medium mb-2 block">
            Select Currency
          </label>
          <select
            className="border border-[#4b2aad] rounded-md w-full py-2 px-3"
            value={currency}
            onChange={(e) =>
              setCurrency(e.target.value as "NGN" | "USD" | "GBP")
            }
          >
            <option value="NGN">NGN (Naira)</option>
            <option value="USD">USD (Dollar)</option>
            <option value="GBP">GBP (Pounds)</option>
          </select>
        </div>
      </div>

      <div className="mb-4 mt-4 w-60 mx-auto flex items-center justify-between">
        <span className="text-xl font-medium text-gray-800">Monthly</span>
        <Switch
          checked={billingCycle === "year"}
          onCheckedChange={toggleBillingCycle}
          thumbColor="primary"
        />
        <span className="text-xl font-medium text-gray-800">Yearly</span>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
        <div className="border rounded-lg p-6 bg-gray-50 shadow-sm flex flex-col">
          <h3 className="text-lg font-semibold mb-4">AI Teacha Free</h3>
          <p className="text-2xl font-bold mb-2">
            {prices.free[currency][billingCycle]}
          </p>

          {billingCycle === "year" && (
            <span className="font-medium text-sm text-gray-700"> </span>
          )}
          <p className="mb-4 mt-2 text-sm text-gray-600">
            Get started for Free, learn how AI Teacha saves you time and
            generates tailored resources.
          </p>
          <ul className="list-disc pl-5 space-y-2 mb-6 flex-grow">
            <strong>Save time, get resources...</strong>
            <li>Unlimited use of our essential free tools</li>
            <li>Generate tailored, high-quality resources</li>
            <li>
              15 Time-Saving Tools to simplify lesson planning, assessments, and
              more
            </li>
            <li>Easily download and save your generated resources</li>
            <li>
              Interact with Zyra, our AI Chat Assistant, built exclusively for
              educators and students
            </li>
            <li>AI Image generation for educators and students</li>
          </ul>
          <button
            className={`w-full py-2 rounded-md mt-auto ${
              userDetails?.package === "AI Teacha Free"
                ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                : "bg-primary text-white hover:bg-[#4a2fa3] transition"
            }`}
            disabled={userDetails?.package === "AI Teacha Free"}
          >
            {userDetails?.package === "AI Teacha Free"
              ? "Current Plan"
              : "Downgrade to Free"}
          </button>
        </div>
        {userDetails?.role == 1 && (
          <div className="border rounded-lg p-6 bg-gray-50 shadow-md flex flex-col">
            <h3 className="text-lg font-semibold mb-4">Admin Plan</h3>
            <p className="text-2xl font-bold mb-2">
              {getCurrencySign(currency)} {prices.admin[currency][billingCycle]}
              <span className="font-medium text-sm text-gray-700">
                {" "}
                per year
              </span>
            </p>
            <p className="mb-4 mt-2 text-sm text-gray-600">
              Upgrade to the Admin Plan for exclusive access to all resources
              and tools.
            </p>
            <ul className="list-disc pl-5 space-y-2 mb-6 flex-grow">
              <strong>Everything in Pro, Plus...</strong>
              <li>Access to admin-specific tools and insights</li>
              <li>Manage and customize advanced user features</li>
              <li>Priority support and early access to beta tools</li>
            </ul>
            <Button
              onClick={() => {
                setSelectedPlan("admin");
                setIsDialogOpen(true);
              }}
              disabled={
                loadingPlan === "admin" || userDetails?.package === "Admin Plan"
              }
              className={`bg-primary text-white w-full py-2 rounded-md transition mt-auto text-center ${
                userDetails?.package === "Admin Plan"
                  ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                  : "hover:bg-[#4a2fa3]"
              }`}
            >
              {userDetails?.package === "Admin Plan"
                ? "Current Plan"
                : loadingPlan === "admin"
                ? "Processing..."
                : "Upgrade to Admin"}
            </Button>
          </div>
        )}

        <div className="border rounded-lg p-6 bg-gray-50 shadow-md flex flex-col">
          <h3 className="text-lg font-semibold mb-4">AI Teacha Pro</h3>
          <p className="text-2xl font-bold mb-2">
            {getCurrencySign(currency)} {prices.pro[currency][billingCycle]}
            {currency === "USD" && billingCycle === "year" && (
              <span className="font-medium text-sm text-gray-700">
                {" "}
                {getCurrencySign(currency)}
                {(prices.pro[currency][billingCycle] / 12).toFixed(2)} monthly
              </span>
            )}
            {currency === "GBP" && billingCycle === "year" && (
              <span className="font-medium text-sm text-gray-700">
                {" "}
                {getCurrencySign(currency)}
                {(prices.pro[currency][billingCycle] / 12).toFixed(2)} monthly
              </span>
            )}
            {billingCycle === "year" && currency === "NGN" && (
              <span className="font-medium text-sm text-gray-700">
                {" "}
                ₦4,585 monthly
              </span>
            )}
          </p>
          <p className="mb-4 mt-2 text-sm text-gray-600">
            Upgrade to AI Teacha Pro for unlimited access to all resources and
            pro tools.
          </p>
          <ul className="list-disc pl-5 space-y-2 mb-6 flex-grow">
            <strong>Everything in Free, Plus...</strong>
            <li>Unlock all 37 advanced, time-saving AI tools</li>
            <li>Unlimited content generation as you need</li>
            <li>
              Generate unlimited AI-powered slides, exportable directly to
              Microsoft PowerPoint
            </li>
            <li>Unlimited assignments for student evaluation needs</li>
            <li>
              Unlimited student performance reports to track and enhance
              learning outcomes
            </li>
            <li>Unlimited AI-generated images perfect for engaging lessons</li>
            <li>Exclusive early access to new features and tools</li>
          </ul>
          <Button
            onClick={() => {
              setSelectedPlan("pro");
              setIsDialogOpen(true);
            }}
            disabled={
              loadingPlan === "pro" || userDetails?.package === "AI Teacha Pro"
            }
            className={`bg-primary text-white w-full py-2 rounded-md transition mt-auto text-center ${
              userDetails?.package === "AI Teacha Pro"
                ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                : "hover:bg-[#4a2fa3]"
            }`}
          >
            {userDetails?.package === "AI Teacha Pro"
              ? "Current Plan"
              : loadingPlan === "pro"
              ? "Processing..."
              : "Upgrade to Pro"}
          </Button>
        </div>

        <div className="border rounded-lg p-6 bg-gray-50 shadow-md flex flex-col">
          <h3 className="text-lg font-semibold mb-4">AI Teacha Premium</h3>
          <p className="text-2xl font-bold mb-2">
            {getCurrencySign(currency)} {prices.premium[currency][billingCycle]}
            {currency === "USD" && billingCycle === "year" && (
              <span className="font-medium text-sm text-gray-700">
                {" "}
                {getCurrencySign(currency)}
                {(prices.premium[currency][billingCycle] / 12).toFixed(2)}{" "}
                monthly
              </span>
            )}
            {currency === "GBP" && billingCycle === "year" && (
              <span className="font-medium text-sm text-gray-700">
                {" "}
                {getCurrencySign(currency)}
                {(prices.premium[currency][billingCycle] / 12).toFixed(2)}{" "}
                monthly
              </span>
            )}
            {billingCycle === "year" && currency === "NGN" && (
              <span className="font-medium text-sm text-gray-700">
                {" "}
                ₦20,385 monthly
              </span>
            )}
          </p>
          <p className="mb-4 mt-2 text-sm text-gray-600">
            Full AI Teacha suite for schools with classroom, assignment, and
            report features.
          </p>
          <ul className="list-disc pl-5 space-y-2 mb-6 flex-grow">
            <strong>Everything in Pro, Plus...</strong>
            <li>
              Institution-wide monitoring of teachers and students activity
            </li>
            <li>
              Moderation features to prioritize student safety and compliance
            </li>
            <li>Data Privacy Agreements (DPA)</li>
            <li>Personalized AI training and tool customizations</li>
            <li>Special pricing and discounts on bulk licenses for schools</li>
            <li>Unlimited chat and resource histories</li>
            <li>Unlimited number of educators</li>
            <li>Dedicated support for your school or institution</li>
          </ul>
          <Button
            onClick={() => {
              setSelectedPlan("premium");
              setIsDialogOpen(true);
            }}
            disabled={
              loadingPlan === "premium" ||
              userDetails?.package === "AI Teacha Premium"
            }
            className={`bg-primary text-white w-full py-2 rounded-md transition mt-auto text-center ${
              userDetails?.package === "premium"
                ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                : "hover:bg-[#4a2fa3]"
            }`}
          >
            {userDetails?.package === "AI Teacha Premium"
              ? "Current Plan"
              : loadingPlan === "premium"
              ? "Processing..."
              : "Upgrade to Premium"}
          </Button>
        </div>
      </div>
      <div className="border rounded-lg mt-4 text-center p-6 bg-gray-50 shadow-md flex flex-col">
        <h3 className="text-lg font-semibold ">AI Teacha Enterprise</h3>
        <p className="mb-4 mt-2 text-sm text-gray-600">
          Custom discounted pricing for schools, districts, institutions, and
          tutorial centers.
        </p>
        <ul className="list-none pl-5 space-y-2 mb-6 flex-grow">
          <strong>Everything in Premium, Plus...</strong>
          <li>Designed for large schools and institutions</li>
          <li>
            The AI Teacha Enterprise Plan is tailored for organizations with 15
            or more educators seeking comprehensive AI solutions
          </li>
          <li>
            Contact us today or use our Quote Calculator to receive customized
            pricing and exclusive discounts for your institution.
          </li>
        </ul>
        <center>
          <Button
            onClick={() => navigate("/dashboard/upgrade/support")}
            disabled={
              loadingPlan === "enterprise" ||
              userDetails?.package === "Ai Teacha Enterprise"
            }
            className={`bg-primary text-white w-full lg:w-1/4  py-2 rounded-md transition mt-auto text-center ${
              userDetails?.package === "Ai Teacha Enterprise"
                ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                : "hover:bg-[#4a2fa3]"
            }`}
          >
            {" "}
            {userDetails?.package === "Ai Teacha Enterprise"
              ? "Current Plan"
              : loadingPlan === "enterprise"
              ? "Processing..."
              : "Contact Support"}{" "}
          </Button>
        </center>{" "}
      </div>
      {isPaymentPage && (
        <center>
          <Link to="/dashboard/home">
            <Button
              variant={"outlined"}
              className="bg-black text-white w-1/4 rounded-md my-4"
            >
              <span className="text-white"> Pay Later</span>
            </Button>
          </Link>
        </center>
      )}
      <div className="mt-12">
        <PricingFaq />
      </div>

      {selectedPlan && (
        <PaymentMethodDialog
          planName={selectedPlan}
          onSelectPaymentMethod={handlePayment}
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default Upgrade;
