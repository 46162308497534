import React from "react";
import StudentPerformanceDashboardOne from "./student-performance-dashboard";
import StudentDashboard from "./student-performance-dashboard2";
const ReportData = () => {
  return (
    <div className="mt-12">
      <StudentDashboard />

      {/* <StudentPerformanceDashboardOne /> */}
    </div>
  );
};

export default ReportData;
