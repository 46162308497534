import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import { loadTools } from "../../store/slices/toolsSlice";
import { Skeleton } from "../../components/ui/Skeleton";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import dashImg from "../../assets/img/0e846ab4-992d-48b3-a818-d62a7803bd8e 1.png";
import { FaDraftingCompass, FaMagic, FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BiImageAdd } from "react-icons/bi";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  DialogClose,
  DialogTrigger,
} from "../../components/ui/Dialogue";
import { Button } from "../../components/ui/Button";
import { checkEligibility } from "../../api/tools";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore from "swiper";
import { Navigation, Pagination, Autoplay, A11y } from "swiper/modules";

SwiperCore.use([Navigation, Pagination, Autoplay, A11y]);

const Home = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { tools, loading, error } = useSelector(
    (state: RootState) => state.tools
  );

  useEffect(() => {
    if (tools.length === 0) {
      dispatch(loadTools());
    }
  }, [dispatch, tools.length]);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [isEmailVerified, setIsEmailVerified] = useState<number>(0);

  useEffect(() => {
    const userDetailsFromStorage = localStorage.getItem("ai-teacha-user");

    if (userDetailsFromStorage) {
      const parsedDetails = JSON.parse(userDetailsFromStorage);
      setUserDetails(parsedDetails);
      setIsEmailVerified(parsedDetails.is_email_verified);
    }
  }, []);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleToolClick = async (toolId: number, slug: string) => {
    try {
      const eligibility = await checkEligibility(toolId);
      if (eligibility) {
        navigate(`/dashboard/tools/${slug}`);
      } else {
        setIsDialogOpen(true);
      }
    } catch (error) {
      setIsDialogOpen(true);
    }
  };

  return (
    <div className="mt-4 ">
      {userDetails && isEmailVerified === 1 && (
        <div
          className="bg-[#e5dbff] mt-3 mb-4 text-black p-4 rounded-md flex justify-center items-center"
          style={{
            background:
              "linear-gradient(143.6deg, rgba(192, 132, 252, 0) 20.79%, rgba(232, 121, 249, 0.26) 40.92%, rgba(204, 171, 238, 0) 70.35%)",
          }}
        >
          <span className="text-center text-xl font-bold">
            Teachers Are Heroes🎉
          </span>
        </div>
      )}
      {loading ? (
        <div className="space-y-4">
          <Skeleton className="h-64 w-full mx-auto rounded-lg" />

          <div className="grid grid-cols-4 gap-4 mt-8">
            {[...Array(8)].map((_, i) => (
              <Skeleton key={i} className="h-16 w-full rounded-lg" />
            ))}
          </div>
        </div>
      ) : error ? (
        <div className="text-red-500 text-center">{error}</div>
      ) : (
        <div>
          <div className="relative mt-4">
            <div className="bg-[#5C3CBB] text-white p-8 rounded-lg overflow-hidden">
              <p className="text-sm font-semibold">Your Journey</p>
              <h2 className="text-2xl font-bold mt-2">
                Hello, Inspiring Educator! 👋
              </h2>
              <p className="text-lg mt-1">
                Empower your students and create meaningful learning experiences
                today.
              </p>
              <Link to={"/dashboard/classrooms/create"}>
                <button className="mt-4 flex hover:bg-gray-200 items-center bg-white text-[#5C3CBB] font-semibold py-2 px-4 rounded-full text-sm">
                  launch classroom
                  <ArrowRightIcon className="h-5 w-5 ml-2" />
                </button>
              </Link>
            </div>

            <img
              src={dashImg}
              alt="Robot reading a book"
              className="absolute lg:block hidden"
              style={{
                height: "300px",
                right: "10%",
                top: "28%",
                transform: "translateY(-50%)",
              }}
            />
          </div>

          <div className="mt-8 flex justify-center">
            <div className="flex gap-4 overflow-x-auto">
              <Link to={"/dashboard/history"}>
                <button className="flex items-center gap-2 bg-purple-200 text-purple-800 rounded-full py-2 px-4 whitespace-nowrap">
                  <FaMagic className="h-5 w-5" />
                  History
                </button>
              </Link>
              <Link to={"/dashboard/chats"}>
                <button className="flex items-center gap-2 bg-blue-200 text-blue-800 rounded-full py-2 px-4 whitespace-nowrap">
                  <FaDraftingCompass className="h-5 w-5" />
                  Chatbot
                </button>
              </Link>
              <Link to={"/dashboard/classrooms"}>
                <button className="flex items-center gap-2 bg-yellow-200 text-yellow-800 rounded-full py-2 px-4 whitespace-nowrap">
                  <BiImageAdd className="h-5 w-5" />
                  Classrooms
                </button>
              </Link>
            </div>
          </div>
          <div className="mt-8 overflow-x-auto py-4">
            {tools.some((tool) => tool.tag === "popular") && (
              <div className="mb-8">
                <h2 className="text-xl font-bold text-gray-900 mb-4 px-2">
                  Popular Tools
                </h2>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 text-center mx-auto">
                  {tools
                    .filter((tool) => tool.tag === "popular")
                    .map((tool) => (
                      <div
                        onClick={() => handleToolClick(tool.id, tool.slug)}
                        key={tool.id}
                        className="flex items-center border border-gray-300 px-4 py-3 rounded-3xl bg-white hover:bg-gray-50 cursor-pointer transition duration-500 ease-in-out transform hover:scale-105"
                        style={{
                          background: "rgba(232, 121, 249, 0.15)", // Reduced opacity here
                          transition: "background 0.3s ease",
                        }}
                      >
                        <div className="text-primary text-2xl mr-4">
                          {tool.thumbnail ? (
                            <img
                              src={
                                tool.thumbnail.startsWith("http")
                                  ? tool.thumbnail
                                  : `https://${tool.thumbnail}`
                              }
                              alt={tool.name || "Tool Thumbnail"}
                              className="w-16 h-16 object-cover rounded-lg"
                            />
                          ) : (
                            <FaHeart className="text-purple-500 w-16 h-16 rounded-lg bg-gray-100 flex items-center justify-center" />
                          )}
                        </div>

                        <div className="text-left">
                          <h3 className="text-base capitalize font-semibold text-gray-900">
                            {tool.name === "math calculator"
                              ? "Solver"
                              : tool.name}
                          </h3>
                          <p className="text-gray-700 text-sm">
                            {tool.description.charAt(0).toUpperCase() +
                              tool.description.slice(1)}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
                {/* <Swiper
                  slidesPerView={3} // Show only 2 cards
                  spaceBetween={30} // Increased space between cards
                  navigation
                  pagination={{ clickable: true }}
                  autoplay={{ delay: 3000 }}
                  breakpoints={{
                    0: { slidesPerView: 1, spaceBetween: 20 },
                    640: { slidesPerView: 1, spaceBetween: 20 },
                    768: { slidesPerView: 3, spaceBetween: 50 },
                    1024: { slidesPerView: 3, spaceBetween: 70 },
                  }}
                  className="popular-tools-swiper"
                >
                  {tools
                    .filter((tool) => tool.tag === "popular")
                    .map((tool) => (
                      <SwiperSlide key={tool.id}>
                        <div
                          onClick={() => handleToolClick(tool.id, tool.slug)}
                          className="flex items-center w-80 h-28 border border-gray-300 px-4 py-4 rounded-3xl bg-white hover:bg-gray-50 cursor-pointer transition duration-500 ease-in-out transform hover:scale-105"
                          style={{
                            background: "rgba(232, 121, 249, 0.15)", // Reduced opacity here
                            transition: "background 0.3s ease",
                          }}
                        >
                          <div className="text-primary text-2xl mr-3">
                            {tool.thumbnail ? (
                              <img
                                src={
                                  tool.thumbnail.startsWith("http")
                                    ? tool.thumbnail
                                    : `https://${tool.thumbnail}`
                                }
                                alt={tool.name || "Tool Thumbnail"}
                                className="w-20 h-20 object-cover rounded-lg"
                              />
                            ) : (
                              <FaHeart className="text-purple-500 w-20 h-20 rounded-lg bg-gray-100 flex items-center justify-center" />
                            )}
                          </div>

                          <div className="text-left">
                            <h3 className="text-lg capitalize font-semibold text-gray-900">
                              {tool.name === "math calculator"
                                ? "Solver"
                                : tool.name}
                            </h3>
                            <p className="text-gray-700 text-sm ">
                              {tool.description.charAt(0).toUpperCase() +
                                tool.description.slice(1)}
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper> */}
              </div>
            )}

            <div className="flex justify-between items-center mb-4 px-2">
              <h2 className="text-xl font-bold text-gray-900">All Tools</h2>
              <Link
                to="/dashboard/tools"
                className="text-sm text-blue-600 hover:underline"
              >
                <button className="text-sm flex rounded-full px-6 py-2 bg-black hover:bg-gray-400 hover:text-black text-white">
                  See All
                  <ArrowRightIcon className="h-5 w-4 ml-2" />
                </button>
              </Link>
            </div>

            <motion.div
              className="flex gap-4"
              whileTap={{ cursor: "grabbing" }}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
            >
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 text-center mx-auto">
                {tools.slice(0, 15).map((tool) => (
                  <div
                    onClick={() => handleToolClick(tool.id, tool.slug)}
                    key={tool.id}
                    className="flex items-center border border-gray-300 px-4 py-3 rounded-3xl bg-white hover:bg-gray-50 cursor-pointer transition duration-500 ease-in-out transform hover:scale-105"
                  >
                    <div className="text-primary text-2xl mr-4">
                      {tool.thumbnail ? (
                        <img
                          src={
                            tool.thumbnail.startsWith("http")
                              ? tool.thumbnail
                              : `https://${tool.thumbnail}`
                          }
                          alt={tool.name || "Tool Thumbnail"}
                          className="w-16 h-16 object-cover rounded-lg"
                        />
                      ) : (
                        <FaHeart className="text-purple-500 w-16 h-16 rounded-lg bg-gray-100 flex items-center justify-center" />
                      )}
                    </div>

                    <div className="text-left">
                      <h3 className="text-base capitalize font-semibold text-gray-900">
                        {tool.name === "math calculator" ? "Solver" : tool.name}
                      </h3>
                      <p className="text-gray-700 text-sm">
                        {tool.description.charAt(0).toUpperCase() +
                          tool.description.slice(1)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      )}

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader className="flex justify-center items-center mx-auto text-center">
            <DialogTitle>Eligibility Check</DialogTitle>
            <DialogDescription className="text-xl font-medium">
              You are not eligible to access this tool.
            </DialogDescription>
            <Link to={"/dashboard/upgrade"}>
              <Button variant={"gradient"} className="rounded-full mt-6">
                Please Upgrade your Plan to use this
              </Button>
            </Link>
          </DialogHeader>
          <DialogFooter>
            <DialogClose
              className=" text-black rounded-lg p-2"
              onClick={() => setIsDialogOpen(false)}
            >
              Close
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Home;
